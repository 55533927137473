import { Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

interface Props {
    show: boolean;
    children: ReactNode;
}

export default function SimpleTransition ({ show, children }: Props) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition.Child>
    </Transition.Root>
  );
}