import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "next-i18next"
import { FC } from "react"
import { useForm } from "react-hook-form"
import { SignInMethod } from "types/common"
import Zod from "zod"

import Button from "components/ui/Button"
import { TextInput } from "components/ui/form"

export interface FormData {
  email: string
  password?: string
}

interface Props {
  loading: boolean
  signInMethod: SignInMethod
  onSubmit: (data: FormData, usedSignInMethod: SignInMethod) => void
}

const schema = Zod.object({
  email: Zod.string().email({ message: "Invalid email address" }),
})

const schemaWithPassword = schema.extend({
  password: Zod.string().min(1),
})

const LoginForm: FC<Props> = ({ loading, signInMethod = "emailLink", onSubmit }) => {
  const { t } = useTranslation("common")
  const prefillEmail = new URLSearchParams(window.location.search).get("email")
  const decodedEmail = prefillEmail && decodeURIComponent(prefillEmail)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(signInMethod === "password" ? schemaWithPassword : schema),
    defaultValues: {
      email: decodedEmail || "",
    },
  })

  const submit = (data: FormData) => {
    onSubmit(data, signInMethod)
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TextInput
        label={t("form.label.email")}
        // placeholder={t("form.label.email")}
        errorMessage={errors.email?.message}
        {...register("email", { required: true })}
      />

      {signInMethod === "password" && (
        <div className="mt-4">
          <TextInput
            label={t("form.label.password")}
            // placeholder={t("form.label.email")}
            password
            errorMessage={errors.password?.message}
            {...register("password", { required: true })}
          />
        </div>
      )}

      <Button
        variant="primary"
        type="submit"
        className={"mt-4 w-full"}
        id="submit"
        loading={loading}
      >
        {t(`loginflow.${signInMethod}.submitButtonLabel`)}
      </Button>
    </form>
  )
}

export default LoginForm
