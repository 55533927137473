import Layout from "layouts/Layout"
import { AuthAction, withAuthUser } from "next-firebase-auth"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import React from "react"

import { SignInMethod } from "types/common"

import { fetchCanSignInWithPassword, sendSignInLink, signInWithPassword } from "lib/firebase/firebaseClient"

import { useLanguageSwitcher } from "hooks/useLangSwitcher"

import LoginForm, { FormData } from "components/LoginFlow/LoginForm"
import SimpleTransition from "components/ui/SimpleTransition"

const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [error, setError] = React.useState<string | null>(null)
  const [loginEmail, setLoginEmail] = React.useState<string | null>(null)
  const [signInMethod, setSignInMethod] = React.useState<SignInMethod>("emailLink")
  const { t } = useTranslation("common")
  const { currentLanguage } = useLanguageSwitcher()

  const handleError = () => {
    setError("Your email is not valid, please contact us")
    setTimeout(() => {
      setError(null)
    }, 5000)
  }

  const onSubmit = async (data: FormData, usedSignInMethod: SignInMethod) => {
    setLoading(true)

    try {
      const canSignInWithPassword = await fetchCanSignInWithPassword(data.email)

      if (canSignInWithPassword && usedSignInMethod !== "password") {
        setSignInMethod("password")
        setLoading(false)

        return
      }

      if (data.password != undefined && data.password !== "") {
        await signInWithPassword(data.email, data.password)
      } else {
        await sendSignInLink(data.email, currentLanguage)

        setLoginEmail(data.email)
      }

      setStep(1)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleError()
    }
  }

  return (
    <Layout>
      <div className="min-h-screen w-full flex items-center justify-center">
        <div className="grid grid-cols-12 h-full w-full">
          <div className="bg-light-grey min-h-screen hidden md:flex flex-col justify-center items-center col-span-12 sm:col-span-6">
            <h1 className=" text-4xl leading-tight font-extrabold text-brand-dark mx-auto max-w-sm">{t("loginflow.title")}</h1>
          </div>
          <div className="min-h-screen bg-light-grey md:bg-white flex flex-col justify-center items-center col-span-12 md:col-span-6 h-full w-full max-w-2xl">
            <div className="w-full flex flex-col  max-w-lg justify-center items-center p-8 relative">
              <div className="w-full max-w-sm">
                {step === 0 && (
                  <>
                    <h1 className="text-3xl font-extrabold leading-tight text-brand-dark mx-auto max-w-sm mb-4 md:hidden">{t("loginflow.title")}</h1>
                    <div className="text-lg md:text-2xl font-medium w-full mb-1">{t(`loginflow.${signInMethod}.getStarted`)}</div>
                    <div className="mb-4">{t(`loginflow.${signInMethod}.getStartedSub`)}</div>

                    <LoginForm onSubmit={onSubmit} loading={loading} signInMethod={signInMethod} />
                  </>
                )}

                <SimpleTransition show={error !== null}>
                  <div className="text-red-500 text-sm font-medium mb-4 mt-1 absolute">{error}</div>
                </SimpleTransition>

                {step === 1 && (
                  <>
                    <div className="text-2xl font-medium w-full mb-8">{t(`loginflow.${signInMethod}.successMessage`, { email: loginEmail })}</div>
                    <div className="text-2xl font-medium w-full mb-8">{t(`loginflow.${signInMethod}.successMessageSub`)}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const getServerSideProps = async ({ locale }: { locale: any }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
})

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Login)
